<template>
<div class="homepage-container scroll-smooth" :class="'route-'+$route.name" :style="{height: innerHeight + 'px'}">

    <div class="bg-white snap-start sticky top-0 z-50 shadow-xl">
        <div class="mx-auto max-w-screen-2xl px-4 md:px-8">
            <header class="flex items-center justify-between border-b py-4 md:py-4 bg-white w-full">
                <!-- logo - start -->
                <router-link to="/" class="inline-flex items-center gap-2.5 text-2xl font-bold text-black md:text-3xl" aria-label="logo">
                    <img src="../assets/images/logo.png" style="height: 36px;"/>
                </router-link>
                <!-- logo - end -->

                <!-- nav - start -->
                <nav class="hidden gap-12 md:flex lg:flex xl:flex items-center">
                    <router-link to="/blog" class="text-lg font-semibold text-gray-600 transition duration-100 hover:text-tourial active:text-indigo-700">Blog</router-link>
                    <router-link to="/contact-us" class="text-lg font-semibold text-gray-600 transition duration-100 hover:text-tourial active:text-indigo-700">Contattaci</router-link>
                    <vs-button @click="$router.push('/explore/login')" primary size="large">Accedi</vs-button>

                </nav>
                <!-- nav - end -->

                <!-- buttons - start -->

                <vs-button size="large" dark @click="() => {$parent.mobileMenuVisible = !$parent.mobileMenuVisible}" v-if="$parent.isMobile" type="button">
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" viewBox="0 0 20 20" fill="currentColor">
                        <path fill-rule="evenodd" d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h6a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z" clip-rule="evenodd" />
                    </svg>

                </vs-button>

                <!-- buttons - end -->
            </header>
        </div>
    </div>

    <div class="homepage-hero-container">
        <div class="pb-6 sm:pb-8 lg:pb-12 homepage-hero">

            <div class="mx-auto max-w-screen-2xl px-2 md:px-6 h-full">

                <section class="flex flex-col items-center h-full">
                    <div class="flex h-full flex-col items-center justify-center text-center ">

                        <h1 class="text-4xl font-bold text-white sm:text-5xl mb-2 md:mb-10 lg:mb-10 xl:mb-10 md:text-8xl">Dai sfogo alla tua<br />voglia di viaggiare</h1>
                        <vs-button :block="$parent.isMobile" @click="$router.push('/explore')" class="mx-0 mb-10" size="large">Inizia ad esplorare!</vs-button>

                    </div>

                </section>
            </div>
        </div>
    </div>

    <div class="bg-white py-12 bg-opacity-30  flex flex-col justify-center snap-start">
        <div class="mx-auto max-w-screen-2xl px-4 md:px-8">
            <!--<p class="mt-10 mb-2 text-center font-semibold text-tourial md:mb-3 lg:text-lg">Introducing</p>-->

            <h2 class="mb-4 text-center text-2xl text-gray-800 md:mb-6 lg:text-3xl"><span class="font-bold"><span class="text-primary">Tourial</span> è la web-app che contiene migliaia di posti da visitare con chi ami,<br /> </span>in Italia e non solo.</h2>

            <p class="mx-auto w-full text-center text-gray-700 md:text-lg mb-5">Le sue funzionalità la renderanno la tua guida di viaggio preferita per vivere esperienze indimenticabili.
                Scoprirai dei veri tesori nascosti, <b>senza perdere tempo e in pochi clic.</b></p>
            <div class="flex w-full flex-col gap-2.5 sm:flex-row sm:justify-center">
                <vs-button @click="$router.push('/explore/login')" style="margin:0;" size="large">Accedi a Tourial!</vs-button>

            </div>
        </div>
    </div>
    <div class="bg-white py-12  flex flex-col justify-center snap-start">
        <carousel :perPageCustom="[[1,1]]" :autoplay="false" :minSwipeDistance="3" :navigationEnabled="!$parent.isMobile" :class="{'dynamic-slide-x': !$parent.isMobile}">
            <slide>
                <div class="mx-auto max-w-screen-xl px-4">
                    <div class="grid gap-8 md:grid-cols-2 lg:gap-12">
                        <div>
                            <div class="flex flex-col justify-center items-center h-full">
                                <img src="../assets/images/Scegli-i-posti-da-visitare.png" loading="lazy" alt="Tourial Explore" style="max-height: 300px; max-width: 100%; width: auto;" />
                            </div>
                        </div>

                        <div class="md:pt-8">
                            <p class="text-center font-bold text-tourial md:text-left">Con Tourial</p>

                            <h1 class="mb-4 text-center text-2xl font-bold text-gray-800 sm:text-3xl md:mb-6 md:text-left">Scegli i posti da visitare</h1>

                            <p class="mb-2 text-gray-500 sm:text-lg text-center md:text-left">
                                Attiva la <span class="text-tourial text-bold">geolocalizzazione</span> e utilizza la mappa della funzionalità <span class="text-tourial text-bold">"Esplora"</span> di Tourial per trovare posti noti e meno noti e decidere dove andare. <br />Leggi le <span class="text-tourial text-bold">schede informative</span>. Salva i tuoi <span class="text-tourial text-bold">preferiti</span>. <br />

                            </p>
                            <div class="my-5">
                                <vs-button :block="$parent.isMobile" @click="$router.push('/explore')" class="mx-0" size="large">Accedi a Tourial!</vs-button>
                            </div>
                        </div>
                    </div>

                </div>
            </slide>
            <slide>
                <div class="mx-auto max-w-screen-xl px-4">
                    <div class="grid gap-8 md:grid-cols-2 lg:gap-12">
                        <div>
                            <div class="flex flex-col justify-center items-center h-full">
                                <img src="../assets/images/Personalizza-il-tuo-viaggio.png" loading="lazy" alt="Tourial Viaggio" style="max-height: 300px; max-width: 100%; width: auto;" />
                            </div>
                        </div>

                        <div class="md:pt-8">
                            <p class="text-center font-bold text-tourial md:text-left">Con Tourial</p>

                            <h1 class="mb-4 text-center text-2xl font-bold text-gray-800 sm:text-3xl md:mb-6 md:text-left">Personalizza il tuo viaggio</h1>

                            <p class="mb-2 text-gray-500 sm:text-lg text-center md:text-left">
                                <span class="text-tourial text-bold">Crea il tuo programma di viaggio</span> e organizza tutte le tappe che ti interessano. <br />Con la funzionalità <span class="text-tourial text-bold">“Itinerario”</span>, pianificare una gita o una vacanza non è mai stato così <span class="text-tourial text-bold">facile e veloce</span>.
                            </p>
                            <div class="my-5">
                                <vs-button :block="$parent.isMobile" @click="$router.push('/explore')" class="mx-0" size="large">Accedi a Tourial!</vs-button>
                            </div>

                        </div>
                    </div>

                </div>
            </slide>
            <slide>
                <div class="mx-auto max-w-screen-xl px-4">
                    <div class="grid gap-8 md:grid-cols-2 lg:gap-12">
                        <div>
                            <div class="flex flex-col justify-center items-center h-full">
                                <img src="../assets/images/Entra-a-far-parte-della-community.png" loading="lazy" alt="Tourial Community" style="max-height: 300px; max-width: 100%; width: auto;" />
                            </div>
                        </div>

                        <div class="md:pt-8">
                            <p class="text-center font-bold text-tourial md:text-left">Con Tourial</p>

                            <h1 class="mb-4 text-center text-2xl font-bold text-gray-800 sm:text-3xl md:mb-6 md:text-left">Entra a far parte di una community</h1>

                            <p class="mb-2 text-gray-500 sm:text-lg text-center md:text-left">
                                In <span class="text-tourial text-bold">Tourial</span> puoi <span class="text-tourial text-bold">recensire</span> tutti i posti che visiterai e <span class="text-tourial text-bold">scambiare</span> consigli di viaggio con altri appassionati viaggiatori come te!
                                <br />Cosa c'è di più bello di un viaggio se non condividerlo?
                            </p>
                            <div class="my-5">
                                <vs-button :block="$parent.isMobile" @click="$router.push('/explore')" class="mx-0" size="large">Accedi a Tourial!</vs-button>
                            </div>

                        </div>
                    </div>

                </div>
            </slide>

        </carousel>

    </div>
    <div class="bg-white py-12 bg-opacity-30 flex flex-col justify-center snap-start snap-start">
        <div class="mx-auto w-full px-4" style="box-sizing: border-box;">
            <!-- text - start -->
            <div class="mb-10 md:mb-16 px-8">
                <h2 class="mb-4 text-center text-2xl font-bold text-gray-800 md:mb-6 lg:text-3xl">I nostri consigli di viaggio</h2>

                <p class="mx-auto w-full text-center text-gray-700 md:text-lg">Leggi tutti gli articoli nel Blog di Tourial per trovare tante idee di viaggio.</p>
            </div>
            <!-- text - end -->

            <carousel v-if="articles.length > 0" :perPageCustom="$parent.isMobile ? [[1,1]] : [[1, 3]]" :autoplay="true" :navigationEnabled="!$parent.isMobile" :minSwipeDistance="3" class="mt-10 mb-6" :class="{'dynamic-slide-x': !$parent.isMobile}">
                <slide v-for="article in articles" :key="'article-' + article.id">
                    <!-- article - start -->
                    <router-link :to="('/blog/post/' + (article.slug != undefined ? article.slug : article.id))" class="group relative flex h-56 flex-col overflow-hidden rounded-lg bg-gray-100 shadow-lg md:h-64 xl:h-96 mb-5" :class="{'mx-5': !$parent.isMobile}">
                        <img :src="baseUri + ('/blog/articles/' + article.id + '/pictures/' + article.picture_id + '?type=MAIN')" loading="lazy" :alt="article.title" class="absolute inset-0 h-full w-full object-cover object-center transition duration-200 group-hover:scale-110" />

                        <div class="pointer-events-none absolute inset-0 bg-gradient-to-t from-gray-800 to-transparent md:via-transparent"></div>

                        <div class="relative mt-auto p-4">
                            <span class="block text-sm text-gray-200">{{ (new Date(article.updated_at)).toLocaleDateString() }}</span>
                            <h2 class="mb-2 text-xl font-semibold text-white transition duration-100">{{ article.title }}</h2>

                            <span class="font-semibold text-tourial">Continua a leggere</span>
                        </div>
                    </router-link>
                </slide>
            </carousel>
            <!-- article - end -->

            <div class="flex w-full flex-col gap-2.5 sm:flex-row sm:justify-center mt-5">
                <vs-button @click="$router.push('/blog')" size="large" style="margin: 0;">Vai al Blog!</vs-button>

            </div>
        </div>
    </div>
    <div class="bg-white py-12 snap-start">
        <div class="mx-auto max-w-screen-2xl px-4 md:px-8">
            <!-- text - start -->
            <div class="mb-10 md:mb-16">
                <h2 class="mb-4 text-center text-2xl font-bold text-gray-800 md:mb-6 lg:text-3xl">Come è nata Tourial</h2>

                <p class="mx-auto w-full text-center text-gray-500 md:text-lg">Guarda il video in cui ti raccontiamo come è nata Tourial.</p>
            </div>
            <!-- text - end -->

            <div class="flex flex-row justify-center">
                <!-- image - start -->

                <a @click="() => {youtubePlayer = true}" class="group relative flex h-48 items-end justify-end overflow-hidden rounded-lg bg-gray-100 shadow-lg md:h-96 " style="cursor: pointer;width: 640px;">
                    <img v-show="!youtubePlayer" src="../assets/images/thumbnail.png" loading="lazy" class="absolute inset-0 h-full w-full object-cover object-center transition duration-200 group-hover:scale-110" />
                    <div v-show="youtubePlayer" id="ytplayer"></div>

                    <div class="pointer-events-none absolute inset-0 bg-gradient-to-t from-gray-800 via-transparent to-transparent opacity-50"></div>

                    <span class="relative mr-3 mb-3 inline-block rounded-lg border border-gray-500 px-2 py-1 text-xs text-gray-200 backdrop-blur md:px-3 md:text-sm">YouTube</span>
                </a>
                <!-- image - end -->
            </div>
        </div>
    </div>
    <footer class="bg-white" style="border-top: 1px solid rgb(199, 199, 199);">
        <div class="pb-16 pt-4 sm:pt-10 lg:pt-12">
            <div class="mx-auto max-w-screen-2xl px-4 md:px-8">
                <div class="grid grid-cols-1 gap-12 border-t pt-10 md:grid-cols-3 lg:grid-cols-5 lg:gap-8 lg:pt-12">
                    <div class="col-span-full lg:col-span-2">
                        <!-- logo - start -->
                        <div class="mb-4 lg:-mt-2">
                            <router-link to="/" class="inline-flex items-center gap-2 text-xl font-bold text-black md:text-2xl" aria-label="logo">
                                <img src="../assets/images/logo.png" style="height: 36px;"/>

                            </router-link>
                        </div>
                        <!-- logo - end -->

                        <!--<p class="text-gray-500 sm:pr-8">Sei pronto a viaggiare con Tourial? Arriva il sito web definitivo per esplorare e pianificare le tue avventure, tutto a portata di mano.</p>-->
                    </div>

                    <!-- nav - start -->
                    <div>
                        <div class="mb-4 font-bold uppercase tracking-widest text-gray-800">Sezioni utili</div>

                        <nav class="flex flex-col gap-4">
                            <div>
                                <router-link to="/privacy-policy" class="text-gray-500 transition duration-100 hover:text-tourial active:text-indigo-600">Privacy Policy</router-link>
                            </div>
                            <div>
                                <router-link to="/cookie-policy" class="text-gray-500 transition duration-100 hover:text-tourial active:text-indigo-600">Cookie Policy</router-link>
                            </div>

                            <div>
                                <router-link to="/tos" class="text-gray-500 transition duration-100 hover:text-tourial active:text-indigo-600">Termini e condizioni</router-link>
                            </div>
                        </nav>
                    </div>
                    <!-- nav - end -->

                    <!-- nav - start -->
                    <div>
                        <div class="mb-4 font-bold uppercase tracking-widest text-gray-800">Contatti</div>

                        <nav class="flex flex-col gap-4">
                            <div>
                                <a class="text-gray-500 transition duration-100 hover:text-tourial active:text-indigo-600">Tourial Founder</a>
                            </div>

                            <div>
                                <a class="text-gray-500 transition duration-100 hover:text-tourial active:text-indigo-600">Elena Colombo</a>
                            </div>

                            <div>
                                <a class="text-gray-500 transition duration-100 hover:text-tourial active:text-indigo-600">Via Casati 25, Lesmo (MB)</a>
                            </div>

                            <div>
                                <a href="mailto:info@tourial.it" class="text-gray-500 transition duration-100 hover:text-tourial active:text-indigo-600">info@tourial.it</a>
                            </div>
                        </nav>
                    </div>
                    <!-- nav - end -->

                    <!-- nav - start -->
                    <div>
                        <div class="mb-4 font-bold uppercase tracking-widest text-gray-800">Social</div>

                        <nav class="flex flex-row gap-4">
                            <!-- social - start -->
                            <a href="https://www.facebook.com/profile.php?id=100090998845042" target="_blank" class="text-gray-400 transition duration-100 hover:text-gray-500 active:text-gray-600">
                                <img src="../assets/icons/fb_orange.png" alt="Facebook" class="h-8 h-8" />
                            </a>
                            
                            <a href="https://instagram.com/tourial.it" target="_blank" class="text-gray-400 transition duration-100 hover:text-gray-500 active:text-gray-600">
                                <img src="../assets/icons/ig_orange.png" alt="Instagram" class="h-8 h-8" />
                            </a>


                            <a href="https://www.tiktok.com/@tourial.it" target="_blank" class="text-gray-400 transition duration-100 hover:text-gray-500 active:text-gray-600">
                                <img src="../assets/icons/tt_orange.png" alt="TikTok" class="h-8 h-8" />
                            </a>

                            <a href="https://www.youtube.com/@Tourial.it." target="_blank" class="text-gray-400 transition duration-100 hover:text-gray-500 active:text-gray-600">
                                <img src="../assets/icons/yt_orange.png" alt="YouTube" class="h-8 h-8" />
                            </a>
                            <!-- social - end -->
                        </nav>
                    </div>
                    <!-- nav - end -->
                </div>
            </div>
        </div>

        <div class="bg-black bg-opacity-80">
            <div class="mx-auto max-w-screen-2xl px-4 md:px-8">
                <div class="flex items-center justify-between gap-4 py-8">
                    <span class="text-sm text-gray-400"> © 2024 - <span class="text-tourial">Tourial.</span>
                        All rights reserved.<br v-if="$parent.isMobile" />
                        P.IVA IT12881850965</span>

                </div>
            </div>
        </div>
    </footer>
</div>
</template>

<style scoped>
@import '../tailwind.css';
</style>

<script>
import {
    Carousel,
    Slide
} from 'vue-carousel';
import {
    apiCall,
    baseUri
} from '../utils/ApiMiddleware.js';

export default {
    components: {
        Carousel,
        Slide
    },

    props: {
        innerHeight: {
            type: Number,
            default: 100,
            required: false
        }
    },

    data() {
        return {
            articles: [],
            baseUri,
            youtubePlayer: false
        }
    },

    methods: {
        async getBlogArticles(reset = true) {
            const loading = this.$vs.loading();

            const response = await apiCall('GET', '/blog/articles', {
                limit: 4,
                after: null,
                order: 'desc(updated_at)'
            });
            loading.close();
            if (response.status == 200) {
                if (reset) {
                    this.articles = [];
                }
                this.result = response.data;
                if (response.data.data != null) {
                    this.articles = this.articles.concat(response.data.data);
                }

            } else if (response.status != 0) {
                this.$vs.notification({
                    title: this.$t('common.messages.somethingWentWrong'),
                    text: this.$t('blog.messages.cannotLoadPosts'),
                    color: 'danger',
                    position: 'top-right'
                });
            }
        },
    },
    watch: {
        youtubePlayer(n) {
            if (n) {
                document.getElementById('ytplayer').style.display = 'block';
            } else {
                document.getElementById('ytplayer').style.display = 'none';
            }
        }
    },

    mounted() {
        this.getBlogArticles();
        // Load the IFrame Player API code asynchronously.
        var tag = document.createElement('script');
        tag.src = "https://www.youtube.com/player_api";
        var firstScriptTag = document.getElementsByTagName('script')[0];
        firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

        window.onYouTubePlayerAPIReady = function () {
            new window.YT.Player('ytplayer', {
                height: '360',
                width: '640',
                videoId: 'tvLmxfJwXQ4',
                autoplay: 1,
            });
        }
    }
}
</script>

<style>
.homepage-hero {
    /* background linear gradient overlayed on background image */
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.1));
    background-size: cover;
    height: calc(100vh - 93px);
}

.homepage-hero-container {
    background-image: url('../assets/images/homepage-hero.jpg');
    background-size: cover;
    background-position-x: 22%;
    background-position-y: bottom;

}

.homepage-container {
    padding-top: 0 !important;
    overflow-x:hidden;
}

.group:hover img {
    transform: scale(1.2);
}

iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.VueCarousel-dot,
.VueCarousel-dot-container {
    margin-top: 0 !important;
}

.flashing {
    animation: flashing 3s infinite ease-in-out;
}

@keyframes flashing {

    0%,
    60%,
    100% {
        color: #fff;
        text-shadow: none
    }

    80% {
        color: rgba(var(--vs-primary));
        text-shadow: 0 0 1px rgba(var(--vs-primary));
    }
}

/* desktop only */
@media (min-width: 1024px) {

    .homepage-hero-container {
        background-position-x: center;
    }
}

.VueCarousel-navigation-prev, .VueCarousel-navigation-next {
    transform: unset!important;
}

.VueCarousel-navigation-button {
    background-color: rgba(var(--vs-primary))!important;
    color: transparent!important;
    border-radius: 100%!important;
    width: 50px!important;
    height: 50px!important;
    display: flex!important;
    align-items: center!important;
    justify-content: center!important;
    margin-left: 10px!important;
    margin-right: 10px!important;
    background-position: center;
    background-repeat: no-repeat;
    background-size: 50%;
    top: calc(50% - 50px)!important;
}

.VueCarousel-navigation-prev {
    left: 3%!important;
    background-image: url(../assets/icons/nav-left.png);

}

.VueCarousel-navigation-next {
    right: 3%!important;
    background-image: url(../assets/icons/nav-right.png);
}

.homepage-container a {
    text-decoration: none!important;
}

/* desktop only */
@media (min-width: 1024px) {
    .dynamic-slide-x {
        padding-left: calc(9% + 50px)!important;
        padding-right: calc(9% + 50px)!important;
    }
}

</style>
